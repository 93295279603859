import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import ReactLogo from '../../assets/images/menuclose-icon.svg';
import './header.css';

const Header = () => {
  const location = useLocation();
  const isArticlesActive = location.pathname.startsWith(`${process.env.PUBLIC_URL}/banu-speaks`);

    var pageWidth = window.innerWidth;
    var body= document.getElementsByTagName('body')[0];
    var script= document.createElement('script');
    script.type= 'text/javascript';
    
    if (pageWidth >801) {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        const scrollanimi = "sscroll-animi";
        let lastScroll = 0;
        
        window.addEventListener("scroll", () => {
          const currentScroll = window.pageYOffset;
          if (currentScroll <= 0) {
            body.classList.remove(scrollUp);
            body.classList.remove(scrollanimi);
            return;
          }
  
          if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {	
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
            body.classList.add(scrollanimi);
          } else if (
            currentScroll < lastScroll &&
            body.classList.contains(scrollDown)
          ) {
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
            body.classList.add(scrollanimi);
          }
          lastScroll = currentScroll;
        });
        
    }
    
    else{
        
    };
    body.appendChild(script);

    const [shadow, setShow] = React.useState();

  return (
    <div className='ComHeader'>       
      <header>       
          <div className="HeadContainer">
              <div className="headlogo"><Link to={`${process.env.PUBLIC_URL}/`}><img src={require("../../assets/images/banusekar-logo.png")} alt="LOGO"/></Link></div>
              <nav className="nav">
                  <ul className="BSmainMenu">
                      <li className="nav__item"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/`} className="nav__link">Home</NavLink></li>
                      <li className="nav__item"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/aboutus`} className="nav__link">About Us</NavLink></li>
                      <li className="nav__item"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/services`} className="nav__link">Services</NavLink></li>
                      <li className={`nav__item haschild ${isArticlesActive ? 'active' : ''}`}><span className="nav__link">Insights</span>
                        <ul>
                          <li className="nav__item"><NavLink to='https://banusekar.in/blog/' target='_blank' className="nav__link">Articles / Legal Updates </NavLink></li>
                          <li className="nav__item"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/banu-speaks`} className="nav__link">Banu Speaks</NavLink></li>
                        </ul>
                      </li>
                      <li className="nav__item"><NavLink to={`${process.env.PUBLIC_URL}/contactus`} className="nav__link">Contact Us</NavLink></li>
                  </ul>
              </nav>
              <div className='mobNav'>
                  <ul>
                    <li className='CallMenu'><Link to="tel:+919840072833"><img src={require ("../../assets/images/mobheadCallImg.png")} alt="Call Us"/><span>Call Us</span></Link></li>
                    <li className='HumbMenu'><Link to="#" id="HumbMenubtn" onClick={() => setShow(true)}><img src={require ("../../assets/images/mobheadMenuImg.png")} alt="Menu"/><span>Menu</span></Link></li>
                  </ul>
              </div>
          </div>
      </header>
      
     
      <div className={shadow ? "shadow" : ""} id="HamburgerMegamenu">	
        <div className="mm-main-container">
          
          <div className="megamenu-header">
            <Link to={`${process.env.PUBLIC_URL}/`}><img src={require("../../assets/images/banusekar-logo.png")} alt=""/></Link>
            <button className="Hamburgermm-close" id="Hamburgermm-close" onClick={() => setShow(false)}><img src={ReactLogo} alt=""/> <span>Close</span></button>
          </div>
          <div className="megamenu-container">
            <div className="mmMenuCon">
                <ul className="HamBSMenu">
                  <li className="nav__item hamnavlink"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/`} className="nav__link" onClick={() => setShow(false)}>Home</NavLink></li>
                  <li className="nav__item hamnavlink"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/aboutus`} className="nav__link" onClick={() => setShow(false)}>About Us</NavLink></li>
                  <li className="nav__item hamnavlink"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/services`} className="nav__link" onClick={() => setShow(false)}>Services</NavLink></li>
                  <li className="nav__item hamnavlink"><NavLink to="https://banusekar.in/blog/" target="_blank" className="nav__link" onClick={() => setShow(false)}>Articles</NavLink></li>
                  <li className="nav__item hamnavlink"><NavLink exact activeClassName="active" to={`${process.env.PUBLIC_URL}/banu-speaks`} className="nav__link" onClick={() => setShow(false)}>Banu Speaks</NavLink></li>
                  <li className="nav__item hamnavlink"><NavLink to={`${process.env.PUBLIC_URL}/contactus`} className="nav__link" onClick={() => setShow(false)}>Contact Us</NavLink></li>
                </ul>
            </div>
            <div className="stcol stcolL">
              <h6>For Enquiries</h6>
              <Link className="contlink phoneno" to="tel:+919363997647">9363997647</Link>
              <Link className="contlink emailid" to="mailto:info@banusekar.com">info@banusekar.com</Link>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Header