import React from 'react';
import { Link } from "react-router-dom";
import "./footer.css"
import EnquirePopupFrom from '../Popups/EnquirePopupFrom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube , faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

const [modalShow, setModalShow] = React.useState(false);

  return (
    <footer>
        <div className='EnquireNSec'>   
            <div className='ENSBgImg'>
                <img src={require ("../../assets/images/footEnowBgImg.jpg")} alt="Banusekar Enquire Now"/>
            </div>
            <div className='textBlock ENTbright' data-aos="fade-right" data-aos-duration="1000">
                <Container>
                    <div class="Enquiretbbox">
                        <h2>Connect with us <br/>for Expert Legal <br/>Solutions</h2>
                        <p className="enquirenpara"><Link className="enquirenLink" href="#" onClick={() => setModalShow(true)}>Enquire Now</Link></p>                        
                        <EnquirePopupFrom
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                </Container>
            </div>
            {/*<div className='textBlock ENTbleft' data-aos="fade-left" data-aos-duration="1000">
                <p className="absimg"><img src={require ("../../assets/images/askbs-img2.png")} alt="Ask Banusekar" /></p>
            </div>*/}
        </div>
        <div className='MfooterSec'>
            <Container>
                <Row>
                    <Col className='mfsCol-One'>
                        <div className='textBlock'>
                            <h4>Services</h4>
                            <ul className="fBSmMenu">
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/taxation`} className="nav__link">Taxation</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/international-taxation`} className="nav__link">International Taxation</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/master-of-transfer`} className="nav__link">Transfer Pricing</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/blackmoney`} className="nav__link">Black Money</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/benami`} className="nav__link">Prohibition of Benami Property Transactions</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/money-laundering-lawyer`} className="nav__link">Prevention of Money Laundering</Link></li>
                            </ul>
                
                        </div>
                    </Col>
                    <Col className='mfsCol-Two'>
                        <div className='textBlock'>
                            <h4>Menu</h4>
                            <ul className="fBSmMenu">
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/`} className="nav__link">Home</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/aboutus`} className="nav__link">About Us</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/services`} className="nav__link">Services</Link></li>
                                <li className="nav__item"><Link to='https://banusekar.in/blog/' target="_blank" rel="noopener">Articles / Legal Updates</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/banu-speaks`} className="nav__link">Banu Speaks</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/contactus`} className="nav__link">Contact Us</Link></li>
                            </ul>
                        </div>
                    </Col>
                    {/* <Col className='mfsCol-Three'>
                        <div className='textBlock'>
                            <h4>Banusekar</h4>
                            <ul className="fBSmMenu">
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/`} className="nav__link">As Legal Counsel</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/`} className="nav__link">As a Teacher / Faculty</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/`} className="nav__link">As an Author</Link></li>
                                <li className="nav__item"><Link to={`${process.env.PUBLIC_URL}/`} className="nav__link">As a Speaker</Link></li>
                            </ul>                        
                        </div>
                    </Col> */}
                    <Col className='mfsCol-four'>
                        <div className='textBlock fcrighttb'>
                            <p className="fcright">Copyright © 2025 Banusekar,<br/> All Rights Reserved</p>
                            <h5>Follow Us</h5>
                            <ul className='smedialink'>
                                <li><Link to="https://www.linkedin.com/in/banusekar-thyagarajan-7495b917/?originalSubdomain=in/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></Link></li>
                                <li><Link to="https://www.youtube.com/@BanuSpeaks"><FontAwesomeIcon icon={faYoutube} /></Link></li>
                            </ul>
                            
                        </div>
                        <div className='textBlock beopenCol'>
                            <p><Link to="https://stoppress.in/" target="_blank" rel="noopener"></Link></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>        
        
        <div className="ABanubtnfix"><Button  onClick={() => setModalShow(true)}><span>Talk To Us</span></Button></div>
        <div className='ABanubtnfix ABanubtnfixwp'>
                  <ul>
                    <li className='CallMenu desktop'><Link to="https://web.whatsapp.com/send?phone=+919363997647?text=" target='_blank'><img src={require ("../../assets/images/whatsapp-icon.png")} alt="Whatapp"/></Link></li>
                    <li className='CallMenu mobile'><Link to="https://wa.me/+919363997647?text=" ><img src={require ("../../assets/images/whatsapp-icon.png")} alt="Whatapp"/></Link></li>

                  </ul>
              </div>
    </footer>
  )
}

export default Footer